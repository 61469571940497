import Image from 'next/future/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import frmeasure from '@/public/images/french-size-guide-green.svg';
import measure from '@/public/images/measure-green.svg';

import { getSection } from '../utility';
import UnitSizeCard from './unit-size-card';
import UnitSizeMobileCard from './unit-size-card-mobile';

const LiCard = (props: any) => {
  const { unit } = props;
  const { locale }: any = useRouter();
  const [activeBtn, setActiveBtn] = useState('S');
  const unitSizes = unit?.Unit_Sizes;
  const unitSizeSymbol =
    unitSizes?.split('-')[0].trim() === 'Parking'
      ? 'P'
      : unitSizes?.split('-')[0].trim();

  const setButtonValue = (e: any) => {
    let valueOrigin = e.target.textContent.trim();
    if (locale === 'fr') {
      if (valueOrigin === 'P') {
        valueOrigin = 'S';
      } else if (valueOrigin === 'M') valueOrigin = 'M';
      else if (valueOrigin === 'G') valueOrigin = 'L';
      else if (valueOrigin === 'TG') valueOrigin = 'XL';
      else if (valueOrigin === 'S') valueOrigin = 'P';
    }
    setActiveBtn(valueOrigin);
    props.activeBtn(valueOrigin);
  };
  return (
    <li className="nav-item" role="presentation">
      <button
        className={`nav-link   ${activeBtn === unitSizeSymbol ? 'active' : ''}`}
        id={unit.id}
        data-bs-toggle="pill"
        data-bs-target="#pills-small"
        type="button"
        role="tab"
        aria-controls="pills-small"
        onClick={(e) => setButtonValue(e)}
        aria-selected="true"
      >
        {locale === 'en' && unitSizeSymbol}
        {locale === 'fr' && unitSizeSymbol === 'S' && 'P'}
        {locale === 'fr' && unitSizeSymbol === 'M' && 'M'}
        {locale === 'fr' && unitSizeSymbol === 'L' && 'G'}
        {locale === 'fr' && unitSizeSymbol === 'XL' && 'TG'}
        {locale === 'fr' && unitSizeSymbol === 'P' && 'S'}
      </button>
    </li>
  );
};
const FindTheRightUnits = (props: any) => {
  const section = getSection(props);
  const title =
    section && section[6] && section[6].Title
      ? section && section[6] && section[6].Title
      : section && section.Title;
  const units =
    section && section[7] && section[7].Units
      ? section && section[7] && section[7].Units
      : section && section.Units;
  const [activeBtnPar, setActiveBtnPar] = useState('S');
  const setActive = (btnValue: string) => {
    setActiveBtnPar(btnValue);
  };

  const { locale }: any = useRouter();
  useEffect(() => {
    setActiveBtnPar('S');
  }, [locale]);

  return (
    <section className="find-unit-size">
      <div className="container">
        <h2 className="section-heading">
          {title || 'Find The Right Unit Size'}
        </h2>
        <div className="unit-sizes">
          <div className="row d-none d-xl-block d-lg-block d-md-block">
            <div className="col-xl-12">
              <div className="card scale-card">
                <div className="row">
                  <div className="col-xl-12 text-center">
                    <Image
                      sizes="(max-width: 768px) 720px, (max-width: 992px) 960px, (max-width: 1280px) 1140px"
                      src={locale === 'en' ? measure : frmeasure || ''}
                      alt="scale"
                      width="600px"
                      height="75px"
                    ></Image>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="details d-none d-xl-block d-lg-block d-md-block">
            <div className="row g-3 justify-content-between">
              {units &&
                units.map((unit: any) => (
                  <UnitSizeCard key={unit.id} unit={unit}></UnitSizeCard>
                ))}
            </div>
          </div>
          <div className="mobile-view-details d-block d-xl-none d-lg-none d-md-none">
            <div className="row">
              <div className="col-sm-12">
                <ul
                  className="nav nav-pills nav-fill mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  {units &&
                    units.map((unit: any) => (
                      <LiCard
                        key={unit.id}
                        unit={unit}
                        activeBtn={setActive}
                      ></LiCard>
                    ))}
                </ul>
                <div
                  className="tab-content"
                  id="pills-tabContent"
                  key={activeBtnPar}
                >
                  {units &&
                    units.map((unit: any) => (
                      <UnitSizeMobileCard
                        key={unit.id}
                        unit={unit}
                        activeBtnPar={activeBtnPar}
                      ></UnitSizeMobileCard>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FindTheRightUnits;
