import dynamic from 'next/dynamic';
import Image from 'next/future/image';

const Embed = dynamic(() => import('../common/Embed'));

const UnitSizeMobileCard = (props: any) => {
  const { unit, activeBtnPar } = props || null;
  const { Title, Description, Thumbnail } = unit;
  const unitSizes = unit?.Unit_Sizes;
  const videoUrl = unit?.Video_URL;
  const imageUrl =
    Thumbnail?.data &&
    Thumbnail?.data.length &&
    Thumbnail?.data[0]?.attributes?.url;
  const unitSizeSymbol =
    unitSizes && unitSizes?.split('-')[0].trim() === 'Parking'
      ? 'P'
      : unitSizes && unitSizes?.split('-')[0].trim();
  const alternativeText =
    Thumbnail?.data &&
    Thumbnail?.data.length &&
    Thumbnail?.data[0]?.attributes?.alternativeText;
  return (
    <>
      <div
        className={`tab-pane fade ${
          activeBtnPar === unitSizeSymbol ? 'show active' : ''
        }`}
        role="tabpanel"
        aria-labelledby="pills-small-tab"
      >
        <div className="size-spec">{unitSizes?.split('-')[1]}</div>
        <p className="sv-grey-regular-text">{Title}</p>
        <div className="sv-grey-bold-text">{Description}</div>
        <div className="play-AV">
          <div className="video-card">
            {videoUrl && (
              <Embed
                url={videoUrl}
                imageUrl={imageUrl}
                alternativeText={alternativeText}
              />
            )}
          </div>
        </div>

        <div>
          <div className="video-card">
            {!videoUrl && (
              <Image
                src={imageUrl || ''}
                alt={
                  Thumbnail?.data[0]?.attributes?.alternativeText ||
                  Thumbnail?.data[0]?.attributes?.name
                }
                width="600"
                height="375"
                className="img-fluid shadow"
              ></Image>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UnitSizeMobileCard;
