import Image from 'next/future/image';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import playIcon from '@/public/images/icons/play-icon.svg';

import Embed from '../common/Embed';

const UnitSizeCard = (props: any) => {
  const { unit } = props;
  const [show, setShow] = useState(false);
  const { Title, Description, Thumbnail } = unit || null;
  const url =
    Thumbnail?.data &&
    Thumbnail?.data.length &&
    Thumbnail?.data[0]?.attributes?.url;
  const alternativeText =
    Thumbnail?.data &&
    Thumbnail?.data.length &&
    Thumbnail?.data[0]?.attributes?.alternativeText;

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  return (
    <div className="col">
      <div className="card size-panel text-center">
        <div className="sv-grey-small-text">{Title}</div>
        <p className="sv-grey-regular-text para">{Description}</p>
        <div className="image">
          <a className="card video-card" onClick={handleShow}>
            <Image
              src={url || ''}
              alt={alternativeText}
              width="600"
              height="375"
              className="img-fluid thumbnail"
              sizes="(max-width: 375px) 200px, (max-width: 576px) 200px, (max-width: 768px) 125px, (max-width: 992px) 175px"
            ></Image>
            {props.unit.Video_URL && (
              <Image
                src={playIcon || ''}
                alt="play"
                width="40"
                height="40"
                className="img-fluid play-btn"
              ></Image>
            )}
          </a>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} id="unitVideoModal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {props?.unit?.Video_URL ? (
            <Embed
              url={props?.unit?.Video_URL}
              imageUrl={url}
              alternativeText={alternativeText}
              width="775"
              height="435"
            />
          ) : (
            <Image
              src={url || ''}
              alt="play"
              width="775"
              height="435"
              className="img-fluid play-btn"
            ></Image>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UnitSizeCard;
